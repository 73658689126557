import React, {
  startTransition,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LayoutComponent from "../../components/shared/Layout";
import { Button, Tabs } from "react-bootstrap";
import { FaArrowsAltV } from "react-icons/fa";
import Info from "../../components/Info";
import FancyBet from "../../components/FancyBet";
import SportsBook from "../../components/SportsBook";
import SportWidget from "../../components/SportWidget";
import Bookmaker from "../../components/Bookmaker";
import BattingIframe from "../../components/BattingIframe";
import PlaysBet from "../../components/PlaysBet";
import MarketDepth from "../../components/MarketDepth";
import MyBets from "../../components/MyBets";
import LoadingImage from "../../assets/images/magicPlay.jpg";
import RightLogoTv from "../../assets/images/logo.png";
import ReactPlayer from "react-player";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { inRange, isEmpty } from "lodash";
import { apiGet } from "../../Utils/apiFetch";
import apiPath from "../../Utils/apiPath";
import {
  categoryArray,
  premiumCategoryArray,
  priceFormat,
} from "../../Utils/constants";
import Runner from "./Runner";
import { io } from "socket.io-client";
import BetSlipContext from "../../context/BetSlipContext";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import VideoPlayer from "../../components/VideoPlayer";
import { RxCross2 } from "react-icons/rx";
import { useScrollPosition } from "react-use-scroll-position";
import {
  BiSolidVolumeFull,
  BiSolidVolumeLow,
  BiSolidVolumeMute,
} from "react-icons/bi";
import { HiMiniWifi } from "react-icons/hi2";
import RunnerNew from "./RunnerNew";
import LiveMatchTrackerSoccer from "../Score/Soccer.js/ScoreBoardSoccer";
import LiveMatchTrackerTennis from "../Score/Tennis/ScoreBoard";
import LiveMatchTrackerCricket from "../Score/Cricket/ScoerBoardDetail";
const FullMarket = () => {
  const parmas = useParams();
  const {
    user,
    profileData,
    setShowPosition,
    showWidget,
    setActiveWidget,
    activeWidget,
    parly,
    setSocketId,
  } = useContext(AuthContext);
  const {
    setBetSlipObject,
    setShowBetDialog,
    betSlipObject,
    showBetDialog,
    resetBetSlipObject,
    resetBetPosition,
    setResetBetPosition,
    betPlace,
    resetBetPlace,
    setLimit,
  } = useContext(BetSlipContext);
  const ref = useRef(null);
  const { y } = useScrollPosition();
  const [fancySportTab, setFancySportTab] = useState("fancy");
  const [showInfo, setShowInfo] = useState(false);
  const [sportRules, setSportRules] = useState(false);
  const [showMarketDepthPopup, setShowMarketDepthPopup] = useState(false);
  const [showMyBet, setShowMyBet] = useState(false);
  const [topTab, setTopTab] = useState("live");
  const [detail, setDetail] = useState({});
  const [fancyCategoryArray, setFancyCategoryArray] = useState([]);
  const [runner, setRunner] = useState([]);
  const [bookmaker, setBookmaker] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [fancyHeader, setFancyHeader] = useState([]);
  const [sportsBook, setSportsBook] = useState([]);
  const [sportsBookHeader, setSportsBookHeader] = useState([]);
  const [mute, setMute] = useState(true);
  const [score, setScore] = useState("");
  const [scrollHeight, setScrollHeight] = useState(75);
  const [runnerBackOdds, setRunnerBackOdds] = useState([]);
  const [runnerLayOdds, setRunnerLayOdds] = useState([]);
  const [bookmakerMs, setBookmakerMs] = useState(1);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [fancyHeaderKey, setFancyHeaderKey] = useState("All");
  const [fancyUpdated, setFancyUpdate] = useState([]);
  const [position, setPosition] = useState([]);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [marketName, setMarketName] = useState("Match Odds");
  const [sportsBookPosition, setSportsBookPosition] = useState([]);
  const [totalMatched, setTotalMatched] = useState(0);
  const [tieData, setTieData] = useState([]);
  const [tossData, setTossData] = useState([]);
  const [back_oddsTie, setBackOddsTie] = useState([]);
  const [lay_oddsTie, setLayOddsTie] = useState([]);
  const [ms, setMs] = useState(0);
  const [betFairMs, setBetFairMs] = useState(1);
  const [widgetsData, setWidgetsData] = useState([]);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [controls, setControls] = useState(false);
  const [tvClass, setTvClass] = useState(false);
  const [socketObj, setSocketObj] = useState(null);
  const [checkTvRefresh, setTvCheckRefresh] = useState(true);
  const [marketNew, setMarketNew] = useState([]);
  const [activeSportBook, setActiveSportBook] = useState([]);
  const [marketId, setMarketId] = useState([]);
  const [runnerBackOddsOld, setRuunerBackOddsOld] = useState([]);
  const [runnerLayOddsOld, setRuunerlayOddsOld] = useState([]);
  const handleShowBet = () => {
    setShowMyBet(!showMyBet);
  };
  const handleShowInfo = () => {
    setShowInfo(!showInfo);
    if (fancySportTab === "sport") {
      setSportRules(!sportRules);
    }
  };
  const getMatchDetails = async (info) => {
    let obj = !isEmpty(user)
      ? { userId: user?.user?._id, eventId: parmas?.eventId }
      : { eventId: parmas?.eventId };
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail,
      obj
    );
    if (status === 200) {
      if (response_users?.success) {
        if (response_users?.results?.marketList?.length > 1) {
          if (
            response_users?.results?.gameType == "cricket" ||
            response_users?.results?.gameType == "soccer"
          ) {
            let latest = response_users?.results?.marketList?.filter((res) => {
              return res?.jsonData?.length > 0;
            });
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketId(updated);
            }
            let filteredArray = latest?.filter((res) => {
              return res?.marketId == parmas?.marketId;
            });

            setRunner(filteredArray[0]?.jsonData || []);
            setMarketName(filteredArray[0]?.market);
          } else if (response_users?.results?.gameType == "soccer") {
            let latest = response_users?.results?.marketList?.filter((res) => {
              return res?.market !== "Match Odds" && res?.jsonData?.length > 0;
            });
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketId(updated);
            }
            let filteredArray = response_users?.results?.marketList?.filter(
              (res) => {
                return res?.marketId == parmas?.marketId;
              }
            );

            setRunner(filteredArray[0]?.jsonData || []);
            setMarketName(filteredArray[0]?.market);
          } else {
            setMarketName("Match Odds");
            setRunner(response_users?.results?.jsonData || []);
          }
        } else {
          setMarketName("Match Odds");
          setRunner(response_users?.results?.jsonData || []);
        }
        setFancySportTab((prev) => {
          if (info) {
            return prev;
          } else {
            if (response_users?.results?.eventType == 4) {
              return "fancy";
            } else {
              return "sport";
            }
          }
        });
        if (response_users?.results?.matchOdss?.length > 0) {
          listenBetfairOdds({ results: response_users?.results?.matchOdss });
        }
        // if (response_users?.results?.jsonData?.length == 3) {
        //   setTossData(response_users?.results?.jsonData.slice(0, 2) || []);
        // } else {
        //   setTossData(response_users?.results?.jsonData || []);
        // }
        setDetail(response_users?.results);
        // if (response_users?.results?.gameType == "soccer") {
        //   let temp = response_users?.results?.marketList?.sort((a, b) => {
        //     return a.market > b.market;
        //   });
        //   setMarketNew(temp || []);
        //   setRunner(
        //     response_users?.results?.marketList?.filter((res) => {
        //       return res?.marketId == parmas?.marketId;
        //     })[0]?.jsonData || []
        //   );
        // } else {
        //   setRunner(response_users?.results?.jsonData || []);
        // }
        setBookmaker(response_users?.results?.jsonData || []);
        // setFancy(response_users?.results?.fancyList || []);
        setTieData(response_users?.results?.jsonTieData || []);
        getWidgets(response_users?.results);
      }
    }
  };

  // console.log("--------->",runner);
  const getWidgets = async (detail) => {
    const { status, data: response_users } = await apiGet(apiPath.matchList, {
      seriesId: detail?.seriesId,
    });
    if (status === 200) {
      if (response_users?.success) {
        if (response_users?.results?.length > 0) {
          if (isEmpty(activeWidget)) {
            setActiveWidget(detail?._id);
          }
          setWidgetsData(response_users?.results);
        } else {
          setWidgetsData([]);
          setActiveWidget("");
        }
      }
    }
  };
  const getMatchOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : parmas.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          parmas?.eventId == socket_data?.eventId
        ) {
          if (socket_data.rt?.length) {
            const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
            setRunnerBackOdds(back_odds);
            setRunnerLayOdds(lay_odds);
            setMs(socket_data?.ms);
            setTotalMatched(socket_data?.totalMatched);
          }
        }
        if (!isEmpty(id)) {
          setCheckRefresh(true);
        }
      }
    }
  };
  const getSportBookList = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        let filtered =
          response_users?.results?.data?.length > 0
            ? response_users?.results?.data?.filter((res) => {
                return res?.sportsBookSelection?.length > 0;
              })
            : [];
        let filteredHeader = premiumCategoryArray?.filter((f) =>
          filtered?.some((item) => item.categoryType === f.type)
        );
        setSportsBookHeader(filteredHeader);
        setSportsBook(
          filtered?.length > 0
            ? filtered?.map((res, index) => {
                return { ...res, check: index < 5 ? true : false };
              })
            : []
        );
      }
    }
  };
  const getBetPosition = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${parmas.eventId}&type=${detail?.gameType}&marketId=${
          !isEmpty(id) ? id : parmas?.marketId
        }`
    );
    if (status === 200) {
      if (response_users.success) {
        updatePosition(response_users?.results);
      }
    }
  };
  const updatePosition = (object) => {
    setPosition(object);
    setRunner((current) =>
      object?.betFair?.length > 0
        ? current.map((res) => {
            let obj = object?.betFair.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setBookmaker((current) =>
      object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == `B${res?.SelectionId}`
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setTieData((current) =>
      object?.tie?.length > 0
        ? current?.map((res) => {
            let obj = object?.tie?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
    setTossData((current) =>
      object?.toss?.length > 0
        ? current?.map((res) => {
            let obj = object?.toss?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            return {
              ...res,
              position:
                obj?.selectionId == res?.SelectionId ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          })
    );
  };
  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betFancyPosition +
        `?eventId=${parmas.eventId}&type=${detail.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        secureLocalStorage.setItem(
          "fancy",
          response_users?.results?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.position,
              betRunPosition: res?.betRunPosition,
              fName: res?.fancyName,
            };
          })
        );
        updatedFancyPosition(
          response_users?.results?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.position,
              betRunPosition: res?.betRunPosition,
              fName: res?.fancyName,
            };
          })
        );
      }
    }
  };
  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancy((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
    setFancyUpdate((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item.selectionId == res.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };
  const getSportBookBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPremiumFancyPosition +
        `?eventId=${parmas.eventId}&type=${detail.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        secureLocalStorage.setItem(
          "premiumFancy",
          response_users?.results || []
        );
        setSportsBookPosition(response_users?.results);
        setSportsBook((current) => {
          return current?.map((res) => {
            let obj = response_users?.results?.filter((item) => {
              return item?.fancyName == res?.marketName;
            });
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };
  const handelChangeFancyHeader = (id) => {
    if (id == "All") {
      setFancyUpdate(fancy);
    } else if (id == "More") {
      let arr = fancy?.filter(
        (res) =>
          res?.categoryType !== 1 &&
          res?.categoryType !== 2 &&
          res?.categoryType !== 3 &&
          res?.categoryType !== 4 &&
          res?.categoryType !== 5 &&
          res?.categoryType !== 6 &&
          res?.categoryType !== 7 &&
          res?.categoryType !== 8 &&
          res?.categoryType !== 9 &&
          res?.categoryType !== 10 &&
          res?.categoryType !== 11 &&
          res?.categoryType !== 14
      );
      setFancyUpdate(arr);
    } else {
      let temp = fancy?.filter((res) => {
        return res?.categoryType == id;
      });
      setFancyUpdate(temp);
    }
    setFancyHeaderKey(id);
  };
  const listenBetfairOdds = (event) => {
    const socket_data =
      (event &&
        event?.results?.find((item) => item.marketId == parmas?.marketId)) ||
      [];
    if (socket_data && parmas?.marketId == socket_data?.mi) {
      if (socket_data.ms) {
        setBetFairMs(socket_data.ms);
      }
      if (socket_data.rt?.length) {
        const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
        setRunnerBackOdds((prev) => {
          setRuunerBackOddsOld(prev);
          return back_odds;
        });
        setMs(socket_data?.ms);
        setRunnerLayOdds((prev) => {
          setRuunerlayOddsOld(prev);
          return lay_odds;
        });
        setTotalMatched(socket_data?.totalMatched);
      }
    }
  };
  const getBetPositionMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${parmas.eventId}&type=${
          detail?.gameType
        }&marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        let object = response_users?.results?.betFair;
        setMarketNew((current) =>
          object?.length > 0
            ? current?.map((res) => {
                return {
                  ...res,
                  jsonData: res?.jsonData?.map((newObj) => {
                    let obj = object?.find(
                      (item) => item?.selectionId == newObj?.SelectionId
                    );
                    if (!isEmpty(obj)) {
                      return {
                        ...newObj,
                        position:
                          obj?.selectionId == newObj?.SelectionId
                            ? obj?.position
                            : 0,
                        newPosition: 0,
                      };
                    } else {
                      return {
                        ...newObj,
                        position: 0,
                        newPosition: 0,
                      };
                    }
                  }),
                };
              })
            : current
        );
      }
    }
  };

  useEffect(() => {
    if (marketId?.length > 0) {
      setTimeout(() => {
        getBetPositionMulti(marketId);
      }, 3000);
    }
  }, [marketId]);

  const listenBookmakerData = (message) => {
    if (message.eventId === parmas?.eventId) {
      setBookmaker((prev) => {
        return prev?.map((res) => {
          let temp = message?.results?.rt?.find((item) => {
            return item?.name === res?.RunnerName;
          });
          if (temp?.name === res?.RunnerName) {
            return {
              ...res,
              ...temp,
            };
          } else {
            return { ...res, ...temp };
          }
        });
      });
      const back_odds = message?.results?.rt.filter((rt) => rt.ib) || [];
      const lay_odds = message?.results?.rt.filter((rt) => !rt.ib) || [];
      setBookmakerLayOdds(lay_odds);
      setBookmakerMs(!isEmpty(message?.results?.ms) ? message?.results?.ms : 1);
      setBookmakerBackOdds(back_odds);
    }
  };
  const listenDiamondFancy = (message) => {
    if (message?.eventId === parmas?.eventId) {
      if (message?.results?.length > 0) {
        let filtered = categoryArray?.filter((f) =>
          message?.results?.some((item) => item?.categoryType === f.type)
        );
        setFancyHeader(
          filtered?.length > 0
            ? [
                { type: "All", name: "All" },
                ...filtered,
                // { type: "More", name: "More" },
              ]
            : [{ type: "All" }]
        );
        if (!isEmpty(user)) {
          setFancyPosition((prev) => {
            setFancy(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            setFancyUpdate(
              message.results.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              })
            );
            return prev;
          });
        } else {
          setFancy((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
          setFancyUpdate((prev) => {
            return message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            });
          });
        }
      } else {
        if (sportsBook?.length > 0) {
          setFancySportTab("sport");
        }
      }
    }
  };
  const listenSportBook = (message) => {
    const socket_data = message;
    if (socket_data.results && socket_data.eventId == parmas.eventId) {
      if (!isEmpty(user)) {
        setSportsBookPosition((prev) => {
          setSportsBook((current) => {
            let updatedArray =
              socket_data?.results?.length > 0
                ? socket_data?.results?.map((res, index) => {
                    return { ...res, check: index < 5 ? true : false };
                  })
                : [];
            return updatedArray?.map((res) => {
              let obj = prev?.filter((item) => {
                return item?.fancyName == res?.marketName;
              });
              if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
                return {
                  ...res,
                  sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                    let temp = obj?.find(
                      (item) => elem?.id == item?.fancySelectionId
                    );
                    return {
                      ...elem,
                      position: temp?.position,
                    };
                  }),
                };
              } else {
                return res;
              }
            });
          });
          return prev;
        });
      } else {
        // for (let temp of socket_data.results) {
        //   setSportsBook((current) => {
        //     if (current?.length == 0) {
        //       return socket_data.results.filter((res) => {
        //         return res?.sportsBookSelection?.length > 0;
        //       });
        //     } else {
        //       return current?.map((res, index) => {
        //         if (res?.sportsBookSelection?.length > 0) {
        //           if (res?.id == temp?.id) {
        //             return {
        //               ...res,
        //               sportsBookSelection: temp?.sportsBookSelection,
        //               marketStatus: temp?.marketStatus,
        //             };
        //           } else {
        //             return res;
        //           }
        //         }
        //       });
        //     }
        //   });
        // }
      }
    }
  };
  const listenTie = (message) => {
    const socket_data =
      (message &&
        message?.results?.find((item) => item.eventId == parmas?.eventId)) ||
      [];
    if (
      socket_data &&
      socket_data?.eventId &&
      parmas?.eventId == socket_data?.eventId
    ) {
      if (socket_data.rt?.length) {
        const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
        const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
        setBackOddsTie(back_odds);
        setLayOddsTie(lay_odds);
      }
    }
  };
  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner];
    // ?.map((res) => {
    //   return {
    //     ...res,
    //     position: res?.position || 0,
    //     newPosition: res?.newPosition || 0,
    //   };
    // });
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };
  const handelFancyPositions = (slipObj) => {
    if (slipObj?.bidPrice >= 0) {
      let updatedFancy = fancy?.map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      if (slipObj?.object?.ib) {
        let currentPosition = updatedFancy.find(
          (rl) => rl.selectionId == slipObj?.object?.selectionId
        );
        if (
          Math.abs(currentPosition.position) !== 0 &&
          !isEmpty(currentPosition.type)
        ) {
          let backLoss = slipObj?.bidPrice + currentPosition.position;
          currentPosition.newPosition = backLoss;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        } else {
          let backLoss = slipObj?.bidPrice;
          currentPosition.newPosition = backLoss;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        }
      } else {
        let currentPosition = updatedFancy.find(
          (rl) => rl?.selectionId == slipObj?.object?.selectionId
        );
        if (Math.abs(currentPosition?.position) > 0) {
          let backProfit = (slipObj?.bidPrice * slipObj.odds) / 100;
          currentPosition.newPosition = backProfit + currentPosition.position;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        } else {
          let backProfit = (slipObj?.bidPrice * slipObj?.odds) / 100;
          currentPosition.newPosition = backProfit;
          const result = updatedFancy.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyUpdate(result);
          setFancy(result);
        }
      }
    }
  };
  const handelSportBookPositions = (slipObj) => {
    if (slipObj?.bidPrice >= 0) {
      let backProfit = (slipObj.odds - 1) * slipObj?.bidPrice;
      let backLoss = slipObj?.bidPrice;
      setSportsBook((current) => {
        return current?.map((rl) =>
          rl?.id === slipObj?.object?.marketId
            ? {
                ...rl,
                sportsBookSelection: rl?.sportsBookSelection?.map((res) => {
                  return res.id === slipObj?.object?.id
                    ? {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(backProfit) + Number(res.position)
                            : Number(backProfit),
                      }
                    : {
                        ...res,
                        position:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? res.position
                            : 0,
                        newPosition:
                          res.position !== 0 && !isNaN(Math.abs(res.position))
                            ? Number(res.position) - Number(backLoss)
                            : -Number(backLoss),
                      };
                }),
              }
            : rl
        );
      });
    }
  };

  const handelBookmakerPositions = (slipObj) => {
    let selectionsTemp = [...bookmaker]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (slipObj?.bidPrice > 0) {
      if (slipObj?.object.ib) {
        let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
        let backLoss = Number(slipObj?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - Number(backLoss),
                };
              }
            })
          );
        } else {
          let backProfit = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          let backLoss = Number(slipObj?.bidPrice);

          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);

          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + Number(backProfit),
                };
              }
            })
          );
        } else {
          let backProfit = Number(slipObj?.bidPrice);
          let backLoss = Number((slipObj?.odds / 100) * slipObj?.bidPrice);
          setBookmaker(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.SelectionId) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelTiePosition = (slipObj) => {
    let selectionsTemp = [...tieData]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let currentPosition = selectionsTemp.find(
          (rl) => rl.SelectionId == slipObj?.object?.ri
        );
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = selectionsTemp[0];
          thirdTeamPosition = selectionsTemp[1];
        } else {
          otherTeamPosition = selectionsTemp.find(
            (rl) =>
              rl.SelectionId !== slipObj?.object?.ri &&
              rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = selectionsTemp[2] ? selectionsTemp[2] : false;
        }
        let backProfit = (betSlipObject.odds - 1) * betSlipObject?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
        let backLoss = betSlipObject?.bidPrice;
        if (
          Math.abs(selectionsTemp[0]?.position) > 0 ||
          Math.abs(selectionsTemp[1]?.position) > 0
        ) {
          currentPosition.newPosition = currentPosition.position + backProfit;
          otherTeamPosition.newPosition = otherTeamPosition.position - backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              thirdTeamPosition.position - backLoss;
          }
          const result = selectionsTemp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setTieData(result);
        } else {
          currentPosition.newPosition = backProfit;
          otherTeamPosition.newPosition = -backLoss;
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = -backLoss;
          }
          const result = selectionsTemp.map((data) => {
            if (data.SelectionId == currentPosition.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (data.SelectionId == otherTeamPosition.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            if (
              thirdTeamPosition &&
              data.SelectionId == thirdTeamPosition.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition.toFixed(2))
                : 0;
            }
            return data;
          });
          setTieData(result);
        }
      } else {
        let currentPosition = selectionsTemp.find(
          (rl) => rl.SelectionId == slipObj?.object?.ri
        );
        let otherTeamPosition;
        let thirdTeamPosition;
        if (slipObj.RunnerName == "The Draw") {
          otherTeamPosition = selectionsTemp[0];
          thirdTeamPosition = selectionsTemp[1];
        } else {
          otherTeamPosition = selectionsTemp.find(
            (rl) =>
              rl.SelectionId !== slipObj?.object?.ri &&
              rl.RunnerName != "The Draw"
          );
          thirdTeamPosition = selectionsTemp[2] ? selectionsTemp[2] : false;
        }

        if (
          Math.abs(selectionsTemp[0]?.position) > 0 ||
          Math.abs(selectionsTemp[1]?.position) > 0
        ) {
          let backProfit = betSlipObject?.bidPrice;
          let backLoss = (betSlipObject.odds - 1) * betSlipObject?.bidPrice; //(slipObj.rt - 1) * betSelectionObj?.bidPrice;
          currentPosition.newPosition =
            Number(currentPosition?.position) - Number(backLoss);
          otherTeamPosition.newPosition =
            Number(otherTeamPosition?.position) + Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition =
              Number(thirdTeamPosition?.position) + Number(backProfit);
          }
          const result = selectionsTemp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setTieData(result);
        } else {
          let backProfit = betSlipObject?.bidPrice;
          let backLoss = (betSlipObject.odds - 1) * betSlipObject?.bidPrice;
          currentPosition.newPosition = -Number(backLoss);
          otherTeamPosition.newPosition = Number(backProfit);
          if (thirdTeamPosition) {
            thirdTeamPosition.newPosition = Number(backProfit);
          }
          const result = selectionsTemp.map((data) => {
            if (data?.SelectionId == currentPosition?.SelectionId) {
              data.newPosition = currentPosition?.newPosition
                ? parseFloat(currentPosition?.newPosition)
                : 0;
            }
            if (data?.SelectionId == otherTeamPosition?.SelectionId) {
              data.newPosition = otherTeamPosition?.newPosition
                ? parseFloat(otherTeamPosition?.newPosition)
                : 0;
            }
            if (
              thirdTeamPosition &&
              data?.SelectionId == thirdTeamPosition?.SelectionId
            ) {
              data.newPosition = thirdTeamPosition?.newPosition
                ? parseFloat(thirdTeamPosition?.newPosition)
                : 0;
            }
            return data;
          });
          setTieData(result);
        }
      }
    }
  };
  const handelTossPosition = (slipObj) => {
    if (betSlipObject?.bidPrice > 0) {
      let selectionsTemp = [...tossData].map((res) => {
        return {
          ...res,
          newPosition: res?.newPosition || 0,
          position: res?.position || 0,
        };
      });
      let current = selectionsTemp.find((rl) => {
        return rl?.SelectionId === slipObj?.object?.SelectionId;
      });
      let other = selectionsTemp.find((rl) => {
        return rl?.SelectionId !== slipObj?.object?.SelectionId;
      });
      if (Math.abs(current?.position) > 0 || Math.abs(other?.position) > 0) {
        let backProfit = betSlipObject.odds * betSlipObject?.bidPrice;
        let backLoss = betSlipObject?.bidPrice;
        current.newPosition = Number(current?.position) + Number(backProfit);
        other.newPosition = Number(other?.position) - Number(backLoss);
        const result = selectionsTemp.map((data) => {
          if (data?.SelectionId == current?.SelectionId) {
            data.newPosition = current?.newPosition
              ? parseFloat(current?.newPosition)
              : 0;
          }
          if (data?.SelectionId == other?.SelectionId) {
            data.newPosition = other?.newPosition
              ? parseFloat(other?.newPosition)
              : 0;
          }
          return data;
        });
        setTossData(result);
      } else {
        let backProfit = betSlipObject.odds * betSlipObject?.bidPrice;
        let backLoss = -Number(betSlipObject?.bidPrice);
        const result = selectionsTemp.map((data) => {
          if (data?.SelectionId == current?.SelectionId) {
            data.newPosition = backProfit ? parseFloat(backProfit) : 0;
          }
          if (data?.SelectionId == other?.SelectionId) {
            data.newPosition = backLoss ? parseFloat(backLoss) : 0;
          }
          return data;
        });
        setTossData(result);
      }
    }
  };
  const resetPosition = (type) => {
    if (type == "betFair") {
      setRunner((current) =>
        position?.betFair?.length > 0
          ? current?.map((res) => {
              let obj = position?.betFair?.find(
                (item) => item.selectionId == res.SelectionId
              );
              if (!isEmpty(obj)) {
                return {
                  ...res,
                  position:
                    obj.selectionId == res.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              } else {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              }
            })
          : current.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "bookmaker") {
      setBookmaker((current) =>
        position?.bookmaker?.length > 0
          ? current?.map((res) => {
              let obj = position?.bookmaker?.find(
                (item) => item?.selectionId == `B${res?.SelectionId}`
              );
              return {
                ...res,
                position: obj?.position ? obj?.position : 0,
                newPosition: 0,
              };
            })
          : current?.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "fancy") {
      // setFancyUpdate((current) =>
      //   fancyPosition?.length > 0
      //     ? current?.map((res) => {
      //         let obj = fancyPosition?.find(
      //           (item) =>
      //             item.selectionId == res.selectionId &&
      //             item.fancyName == res.fancyName
      //         );
      //         return {
      //           ...res,
      //           position:
      //             obj?.position == 0 || obj?.position ? obj?.position : "",
      //           type: obj?.type,
      //           newPosition: 0,
      //         };
      //       })
      //     : current?.map((res) => {
      //         return {
      //           ...res,
      //           newPosition: 0,
      //         };
      //       })
      // );
      // setFancy((current) =>
      //   fancyPosition?.length > 0
      //     ? current?.map((res) => {
      //         let obj = fancyPosition?.find(
      //           (item) =>
      //             item.selectionId == res.selectionId &&
      //             item.fancyName == res.fancyName
      //         );
      //         return {
      //           ...res,
      //           position: obj?.position ? obj.position : 0,
      //           type: obj?.type,
      //           newPosition: 0,
      //         };
      //       })
      //     : current?.map((res) => {
      //         return {
      //           ...res,
      //           newPosition: 0,
      //         };
      //       })
      // );
    } else if (type == "sportBook") {
      setSportsBook((current) => {
        return sportsBookPosition?.length > 0
          ? current?.map((res) => {
              let obj = sportsBookPosition?.find(
                (item) =>
                  item?.fancyName == res?.marketName &&
                  res?.id == item?.marketId
              );
              return obj?.marketId == res?.id &&
                obj?.fancyName == res?.marketName
                ? {
                    ...res,
                    sportsBookSelection: res?.sportsBookSelection?.map(
                      (temp) => {
                        return temp?.id == obj?.selectionId &&
                          temp?.selectionName == obj?.runnerName
                          ? {
                              ...temp,
                              position: obj?.profitAmount,
                              newPosition: 0,
                            }
                          : {
                              ...temp,
                              position: obj?.loseAmount,
                              newPosition: 0,
                            };
                      }
                    ),
                  }
                : res;
            })
          : current.map((res) => {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((item) => {
                  return { ...item, newPosition: 0 };
                }),
              };
            });
      });
    } else if (type == "tie") {
      setTieData((current) =>
        position?.tie?.length > 0
          ? current?.map((res) => {
              let obj = position?.tie?.find(
                (item) => item.selectionId == res.SelectionId
              );
              return {
                ...res,
                position:
                  obj.selectionId == res.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            })
          : current.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "toss") {
      setTossData((current) =>
        position?.toss?.length > 0
          ? current?.map((res) => {
              let obj = position?.toss?.find(
                (item) => item.selectionId == res.SelectionId
              );
              return {
                ...res,
                position:
                  obj.selectionId == res.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            })
          : current.map((res) => {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            })
      );
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: Math.abs(newObj?.position) > 0 ? newObj?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
    setResetBetPosition(false);
    resetBetSlipObject();
  };
  const updatePositionAfterBetPlace = (type) => {
    if (type == "betFair") {
      secureLocalStorage?.removeItem("betFair");
      secureLocalStorage.setItem(
        "betFair",
        runner?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setRunner(
        runner?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "fancy") {
      secureLocalStorage?.removeItem("fancy");
      secureLocalStorage.setItem(
        "fancy",
        fancy
          ?.filter((res) => {
            return (
              Math.abs(res?.position) > 0 || Math.abs(res?.newPosition) > 0
            );
          })
          ?.map((res) => {
            return {
              selectionId: res?.selectionId,
              position: res?.newPosition || res?.position,
            };
          })
      );
      setFancy((prev) => {
        return prev?.map((res) => {
          return {
            ...res,
            position: res?.newPosition || res?.position,
            newPosition: 0,
          };
        });
      });
      setFancyUpdate((prev) => {
        return prev?.map((res) => {
          return {
            ...res,
            position: res?.newPosition || res?.position,
            newPosition: 0,
          };
        });
      });
    } else if (type == "bookmaker") {
      secureLocalStorage?.removeItem("bookmaker");
      secureLocalStorage.setItem(
        "bookmaker",
        bookmaker?.map((res) => {
          return {
            selectionId: `B${res?.SelectionId}`,
            position: res?.newPosition,
          };
        })
      );
      setBookmaker(
        bookmaker?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "tie") {
      secureLocalStorage?.removeItem("tie");
      secureLocalStorage.setItem(
        "tie",
        tieData?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setTieData(
        tieData?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "toss") {
      secureLocalStorage?.removeItem("toss");
      secureLocalStorage.setItem(
        "toss",
        tossData?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setTossData(
        tossData?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    }
  };
  const handelBetFairPositionsMulti = (slipObj) => {
    let newselection = marketNew?.find((res) => {
      return res?.mi == slipObj?.mi;
    })?.jsonData;
    let selectionsTemp = [...newselection];
    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            }
          });
        } else {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          });
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            }
          });
        } else {
          selectionsTemp = selectionsTemp?.map((rl) => {
            if (rl.SelectionId == slipObj?.object?.ri) {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            } else {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            }
          });
        }
      }
    }
    setMarketNew((res) => {
      return res?.map((item) => {
        if (item?.mi == slipObj?.mi) {
          return { ...item, jsonData: selectionsTemp };
        } else {
          return item;
        }
      });
    });
  };
  useEffect(() => {
    if (showBetDialog) {
      setTvClass(false);
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject);
        } else if (betSlipObject?.playType == "fancy") {
          handelFancyPositions(betSlipObject);
        } else if (betSlipObject?.playType == "sportBook") {
          handelSportBookPositions(betSlipObject);
        } else if (betSlipObject?.playType == "bookmaker") {
          handelBookmakerPositions(betSlipObject);
        } else if (betSlipObject?.playType == "tie") {
          handelTiePosition(betSlipObject);
        } else if (betSlipObject?.playType == "toss") {
          handelTossPosition(betSlipObject);
        } else if (betSlipObject?.playType == "multi") {
          handelBetFairPositionsMulti(betSlipObject);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  useEffect(() => {
    if (resetBetPosition) {
      resetPosition(betSlipObject?.playType);
    }
  }, [resetBetPosition]);

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${parmas.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        // console.log('response_users',response_users?.results)
        // const socket_data = message?.results;
        if (message?.results?.length > 0) {
          let filtered = categoryArray?.filter((f) =>
            message?.results?.some((item) => item?.categoryType === f.type)
          );
          setFancyHeader(
            filtered?.length > 0
              ? [
                  { type: "All", name: "All" },
                  ...filtered,
                  // { type: "More", name: "More" },
                ]
              : [{ type: "All" }]
          );
          if (!isEmpty(user)) {
            setFancyPosition((prev) => {
              setFancy(
                message.results.map((res) => {
                  let obj = prev?.find(
                    (item) => item.selectionId == res.selectionId
                  );

                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                    newPosition:
                      Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                  };
                })
              );
              setFancyUpdate(
                message.results.map((res) => {
                  let obj = prev?.find(
                    (item) => item.selectionId == res.selectionId
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                    newPosition:
                      Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                  };
                })
              );
              return prev;
            });
          } else {
            setFancy((prev) => {
              return message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              });
            });
            setFancyUpdate((prev) => {
              return message?.results?.map((res) => {
                let obj = prev?.find(
                  (item) => item.selectionId == res.selectionId
                );
                return {
                  ...res,
                  odds: res,
                  fancyName: res.name,
                  position: !isEmpty(obj) ? obj?.position : "",
                  newPosition:
                    Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
                };
              });
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    getMatchDetails();
    getMatchOdds();
    getSportBookList();
    getFancyData();
  }, [parmas]);

  useEffect(() => {
    let interval = setInterval(() => {
      getMatchDetails(true);
      getMatchOdds();
      getSportBookList();
      getFancyData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [parmas]);

  useEffect(() => {
    if (!isEmpty(user)) {
      if (!isEmpty(detail)) {
        startTransition(() => {
          getBetPosition();
          getFancyBetPosition();
          getSportBookBetPosition();
        });
      }
    }
  }, [detail]);

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      if (betPlace?.betType == "fancy") {
        getFancyBetPosition();
      } else if (betPlace?.betType == "sportBook") {
        getSportBookBetPosition();
      } else {
        getBetPosition();
      }
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      resetPosition(betPlace?.betType);
    }
  }, [betPlace]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    // e.preventDefault();
    // e.returnValue = "";
    // setPosition({
    //   betFair:
    //     secureLocalStorage.getItem("betFair")?.length > 0
    //       ? secureLocalStorage.getItem("betFair")
    //       : [],
    //   bookmaker:
    //     secureLocalStorage.getItem("bookmaker")?.length > 0
    //       ? secureLocalStorage.getItem("bookmaker")
    //       : [],
    // });
  };

  const getScore = (message) => {
    let updated = JSON.parse(message);
    if (updated?.length > 0) {
      setScore(updated?.find((res) => res?.eventId == detail?.eventId)?.score);
    }
  };

  const getSocket = () => {
    if (detail?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? localStorage.getItem("token") : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?token=${randomId}&userType=front&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            detail?.socketUrl ? detail?.socketUrl : process.env.REACT_APP_SOCKET
          }?user_id=${randomId}&eventId=${detail?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setSocketId(newSocket);
      if (!isEmpty(user)) {
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        if (detail?.gameType == "cricket") {
          newSocket.on("listenDiamondFancy", listenDiamondFancy);
        }
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.emit("setPremiumFancy", {
          user_id: user?.user?._id ? user?.user?._id : randomId,
          eventId: parmas.eventId,
        });
      } else {
        if (detail?.gameType == "cricket") {
          newSocket.on("listenDiamondFancy", listenDiamondFancy);
        }
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
      }
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.disconnect();
    }
  };
  useEffect(() => {
    if (checkRefresh && detail?.eventId) {
      getSocket();
    }
  }, [checkRefresh, detail]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      setTvCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setTvCheckRefresh(false);
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight) + 2
      : 0;
    setScrollHeight(header);
  }, []);

  useEffect(() => {
    var body = document.body,
      html = document.documentElement;
    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    if (height >= 1800) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    } else if (inRange(height, 1200, 10000)) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    } else if (inRange(height, 0, 1200) && tvClass) {
      if (showBetDialog) {
        startTransition(() => {
          setTvClass(false);
        });
      } else {
        if (y >= 298) {
          startTransition(() => {
            setTvClass(true);
          });
        } else {
          startTransition(() => {
            setTvClass(false);
          });
        }
      }
    }
  }, [y, showBetDialog]);

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setControls(false);
    }, 4000);
    return () => window.clearTimeout(timeoutID);
  }, [controls]);

  const getMatchData = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : parmas.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          parmas?.eventId == socket_data?.eventId
        ) {
          if (socket_data.ms) {
            setBetFairMs(socket_data.ms);
          }
          if (socket_data.rt?.length) {
            const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
            setRunnerBackOdds(back_odds);
            setRunnerLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
          }
          if (!isEmpty(id)) {
            setCheckRefresh(true);
          }
        }
      }
    }
  };
  let tabPosition = {
    cricket: "bottom",
    tennis: "top",
    soccer: "bottom",
  };
  const displayScore = () => {
    return detail?.gameType == "soccer" ? (
      <LiveMatchTrackerSoccer
        type="inner"
        tabPosition={tabPosition[detail?.gameType]}
        scoreId={detail?.scoreId}
      />
    ) : detail?.gameType == "tennis" ? (
      <LiveMatchTrackerTennis
        type="inner"
        tabPosition={tabPosition[detail?.gameType]}
        scoreId={detail?.scoreId}
      />
    ) : (
      <LiveMatchTrackerCricket
        type="inner"
        tabPosition={tabPosition[detail?.gameType]}
        scoreId={detail?.scoreId}
      />
    );
  };

  const isBeforeThreeHours = () => {
    const now = detail?.eventDateTime;
    const threeHoursFromNow = moment(now).subtract(3, "hours").toDate();
    return moment(threeHoursFromNow).isAfter(moment().toDate());
  };
  let before3Hr = isBeforeThreeHours();
  return (
    <div>
      <LayoutComponent visibilityType={true} className="position-relative">
        <div className="full-market">
          {!isEmpty(user) &&
            detail?.status == "in_play" &&
            detail?.channel != "false" &&
            !isEmpty(detail.scoreId) &&
            Number(detail?.channel) > 0 &&
            !detail?.eventName.includes(" SRL ") && (
              <>
                <div class="topnav">
                  <p
                    onClick={() => setTopTab("live")}
                    class={topTab === "live" ? "active" : ""}
                  >
                    Live
                  </p>
                  <p
                    onClick={() => setTopTab("scoreboard")}
                    class={topTab === "scoreboard" ? "active" : ""}
                  >
                    ScoreBoard
                  </p>
                </div>
                {topTab === "live" ? (
                  <div className="live-header">
                    <span
                      className="d-flex align-items-center"
                      style={{
                        width: "37%",
                        fontSize: ".9285714285714286rem",
                      }}
                    >
                      {detail?.eventName?.split(" v ")[0]}
                    </span>
                    {detail?.outcome ? (
                      <span
                        className="score_color d-flex justify-content-center align-items-center"
                        style={{
                          width: "25%",
                          fontSize: ".9285714285714286rem",
                        }}
                      >
                        {detail?.outcome != undefined ? detail?.outcome : ""}
                      </span>
                    ) : (
                      "-"
                    )}
                    <span
                      className="d-flex justify-content-end align-items-center"
                      style={{
                        width: "37%",
                        fontSize: ".9285714285714286rem",
                      }}
                    >
                      {detail?.eventName?.split(" v ")[1]}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          {isEmpty(user) &&
            !isEmpty(detail.scoreId) &&
            detail?.status == "in_play" &&
            displayScore()}

          {!isEmpty(user) && detail?.status == "in_play" ? (
            topTab === "live" && detail?.channel != "false" ? (
              <div className={tvClass ? "is-outside" : "tv-outer"}>
                {controls && (
                  <>
                    {tvClass && (
                      <RxCross2
                        onClick={() => setTvClass(false)}
                        className="is-outsite-icon"
                        color="#fff"
                        size={24}
                      />
                    )}
                    {!tvClass && (
                      <div className="is-outsite-icon-mute">
                        {!mute ? (
                          <BiSolidVolumeFull
                            onClick={() => setMute(true)}
                            size={24}
                            color="#c8c7c7"
                            style={{
                              marginRight: "20px",
                            }}
                          />
                        ) : (
                          <BiSolidVolumeMute
                            onClick={() => setMute(false)}
                            size={24}
                            color="#c8c7c7"
                            style={{
                              marginRight: "20px",
                            }}
                          />
                        )}
                        <div className="wifi-icon">
                          {" "}
                          <HiMiniWifi size={24} color="#c8c7c7" />
                          <span>4</span>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {ref?.current?.getSecondsLoaded() == null ||
                ref?.current?.getSecondsLoaded() == 0 ? (
                  <div
                    className="tv-control live_img"
                    style={{
                      padding: "0",
                      position: "absolute",
                      margin: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={LoadingImage}
                      alt="Thumbnail"
                      onClick={() => setControls(true)}

                          style={{
                            overflowY: "hidden",
                            height: "100%",
                            width: "100%",
                            overflowX: "hidden",
                            overflowY: "hidden",
                          }}
                    />
                  </div>
               
                ) : (
                  <div className="is-outsite-icon-new">
                    <img src={RightLogoTv} />
                  </div>
                )}
                {detail.channel && checkTvRefresh && (
                  <ReactPlayer
                    muted={mute}
                    controls={false}
                    ref={ref}
                    playsinline={true}
                    loop={true}
                    playing={true}
                    id="videoFrame"
                    style={{
                      overflowX: "hidden",
                      overflow: "hidden",
                      backgroundColor: "#000000",
                      lineHeight: `0px`,
                    }}
                    onClick={() => setControls(true)}
                    width="100%"
                    height="auto"
                    url={
                      detail?.adsStatus
                        ? detail?.adsContent
                        : `https://mediasrv789-ss247-23-prod-sa-ulivestreaming.com/${detail.channel}/index.m3u8`
                    }
                    config={{
                      file: {
                        attributes: {
                          preload: "none",
                          //forceHLS: true,
                          crossOrigin: "true",
                        },
                        hlsOptions: {
                          //autoStartLoad: false,
                          startLevel: 3,
                        },
                      },
                    }}
                  />
                )}
              </div>
            ) : (
              !isEmpty(detail.scoreId) && displayScore()
            )
          ) : (
            !isEmpty(user) && !isEmpty(detail.scoreId) && displayScore()
          )}

          {!parly ? (
            <>
              {detail?.matchOdds == "on" && (
                <>
                  <div className="balance-label-detail position-relative text-start d-flex justify-content-between exchange-label">
                    <span>Exchange</span>
                    <div className="text-red balance-label-inner">
                      <span>Matched</span>
                      <strong className="px-2">
                        {totalMatched > 0 &&
                          `USD ${priceFormat(totalMatched * 100)}`}
                      </strong>
                      {totalMatched < 10000 && (
                        <strong className="bg-danger rounded text-white low-button">
                          Low
                        </strong>
                      )}
                    </div>
                  </div>

                  {detail?.matchOdds == "on" &&
                    runner?.length > 0 &&
                    runnerBackOdds?.length > 0 && (
                      <Runner
                        ms={detail?.matchOdds == "on" ? ms : 3}
                        parmas={parmas}
                        user={user}
                        setRunner={setRunner}
                        oldBack={
                          detail?.matchOdds == "on" ? runnerBackOddsOld : []
                        }
                        oldLay={
                          detail?.matchOdds == "on" ? runnerLayOddsOld : []
                        }
                        detail={detail}
                        getBetPosition={getBetPosition}
                        tieData={tieData}
                        marketName={marketName}
                        position={
                          position?.betFair?.length > 0 ? position?.betFair : []
                        }
                        setMarketName={setMarketName}
                        totalMatched={totalMatched}
                        runner={runner}
                        market={marketNew}
                        backOdds={
                          detail?.matchOdds == "on" ? runnerBackOdds : []
                        }
                        layOdds={detail?.matchOdds == "on" ? runnerLayOdds : []}
                        back_oddsTie={back_oddsTie}
                        lay_oddsTie={lay_oddsTie}
                        setLimit={setLimit}
                        scrollHeight={scrollHeight}
                        getMatchData={getMatchData}
                        setBetSlipObject={setBetSlipObject}
                        setShowBetDialog={setShowBetDialog}
                        setShowMarketDepthPopup={setShowMarketDepthPopup}
                        handelBetFairPositions={handelBetFairPositions}
                        profileData={
                          detail?.matchSetting?.length > 0
                            ? { ...detail, arrayCheck: "details" }
                            : { ...profileData, arrayCheck: "profile" }
                        }
                      />
                    )}
                </>
              )}
              {detail?.gameType !== "cricket" ||
              detail?.eventName?.includes(" SRL ")
                ? ""
                : detail?.bookMaker == "on" &&
                  bookmaker?.length > 0 && (
                    <Bookmaker
                      user={user}
                      detail={detail}
                      displayType="bookmaker"
                      bookmaker={bookmaker}
                      position={
                        position?.bookmaker?.length > 0 ? position?.bookmaker : []
                      }
                      ms={bookmakerMs}
                      backOdds={bookmakerBackOdds}
                      layOdds={bookmakerLayOdds}
                      betSlipObject={betSlipObject}
                      showBetDialog={showBetDialog}
                      setLimit={setLimit}
                      scrollHeight={scrollHeight}
                      profileData={
                        detail?.matchSetting?.length > 0
                          ? { ...detail, arrayCheck: "details" }
                          : { ...profileData, arrayCheck: "profile" }
                      }
                      setBetSlipObject={setBetSlipObject}
                      setShowBetDialog={setShowBetDialog}
                    />
                  )}
              {/* 
              {detail?.gameType == "soccer" &&
                marketNew?.length > 0 &&
                marketNew?.map((res) => {
                  return (
                    <RunnerNew
                      parmas={parmas}
                      user={user}
                      res={res}
                      newType="multi"
                      betFairMs={!isEmpty(res) ? res?.ms : 3}
                      title={res?.market}
                      setRunner={setRunner}
                      detail={detail}
                      getMatchOdds={getMatchOdds}
                      getBetPosition={getBetPosition}
                      totalMatched={totalMatched}
                      runner={res?.jsonData}
                      market={marketNew}
                      backOdds={
                        res?.back_odds?.length > 0 ? res?.back_odds : []
                      }
                      layOdds={res?.lay_odds?.length > 0 ? res?.lay_odds : []}
                      setLimit={setLimit}
                      scrollHeight={scrollHeight}
                      setBetSlipObject={setBetSlipObject}
                      setShowBetDialog={setShowBetDialog}
                      setShowMarketDepthPopup={setShowMarketDepthPopup}
                      handelBetFairPositions={handelBetFairPositions}
                      profileData={
                        detail?.matchSetting?.length > 0
                          ? { ...detail, arrayCheck: "details" }
                          : { ...profileData, arrayCheck: "profile" }
                      }
                    />
                  );
                })} */}

              {/* {moment(new Date()).isBefore(
                moment(detail.eventDateTime).subtract(40, "m").toDate()
              ) &&
                detail?.matchOdds == "on" &&
                detail?.isTossEnable &&
                runner?.length > 0 &&
                runnerBackOdds?.length > 0 &&
                runnerLayOdds.filter((el) => el?.rt !== 0).length > 0 &&
                runnerBackOdds.filter((el) => el?.rt !== 0).length > 0 &&
                detail?.gameType == "cricket" && (
                  <Bookmaker
                    user={user}
                    displayType="toss"
                    detail={detail}
                    bookmaker={tossData}
                    betSlipObject={betSlipObject}
                    backOdds={[]}
                    showBetDialog={showBetDialog}
                    layOdds={[]}
                    setLimit={setLimit}
                    scrollHeight={scrollHeight}
                    profileData={
                      detail?.matchSetting?.length > 0
                        ? { ...detail, arrayCheck: "details" }
                        : { ...profileData, arrayCheck: "profile" }
                    }
                    setBetSlipObject={setBetSlipObject}
                    setShowBetDialog={setShowBetDialog}
                  />
                )} */}
              {detail?.fancy == "off" && detail?.premiumFancy == "off"
                ? ""
                : (fancy?.filter((item) => {
                    return item?.odds?.rt?.length > 0;
                  })?.length > 0 ||
                    sportsBook?.length > 0) && (
                    <div className="fancy-sport-tab p-2">
                      <div className="fancy-sport-header">
                        <div className="d-flex align-items-center taber">
                          {detail?.fancy == "on" &&
                            fancy?.filter((item) => {
                              return item?.odds?.rt?.length > 0;
                            })?.length > 0 && (
                              <Button
                                className={
                                  fancySportTab === "fancy" && "active-taber"
                                }
                                onClick={() => setFancySportTab("fancy")}
                              >
                                Fancybet
                              </Button>
                            )}
                          {detail?.eventName?.includes(" T10 ")
                            ? ""
                            : detail?.premiumFancy == "on" &&
                              sportsBook?.length > 0 && (
                                <Button
                                  className={
                                    fancySportTab === "sport" && "active-taber"
                                  }
                                  onClick={() => setFancySportTab("sport")}
                                >
                                  {detail?.eventType == 4
                                    ? "Sportsbook"
                                    : "Sportsbook"}
                                </Button>
                              )}
                        </div>
                      </div>

                      {detail.fancy == "on" &&
                      fancySportTab === "fancy" &&
                      fancyUpdated?.length > 0 ? (
                        <FancyBet
                          user={user}
                          detail={detail}
                          handleShowInfo={handleShowInfo}
                          data={fancyUpdated}
                          setLimit={setLimit}
                          header={fancyHeader}
                          fancyHeaderKey={fancyHeaderKey}
                          tvClass={tvClass}
                          scrollHeight={scrollHeight}
                          position={fancyPosition}
                          handelChangeFancyHeader={handelChangeFancyHeader}
                          setShowPosition={setShowPosition}
                          profileData={
                            detail?.matchSetting?.length > 0
                              ? { ...detail, arrayCheck: "details" }
                              : { ...profileData, arrayCheck: "profile" }
                          }
                        />
                      ) : detail?.eventName?.includes(" T10 ") ? (
                        ""
                      ) : (
                        detail?.premiumFancy == "on" && (
                          <SportsBook
                            handleShowInfo={handleShowInfo}
                            data={sportsBook}
                            user={user}
                            detail={detail}
                            setLimit={setLimit}
                            scrollHeight={scrollHeight}
                            tvClass={tvClass}
                            setBetSlipObject={setBetSlipObject}
                            setShowBetDialog={setShowBetDialog}
                            profileData={
                              detail?.matchSetting?.length > 0
                                ? { ...detail, arrayCheck: "details" }
                                : { ...profileData, arrayCheck: "profile" }
                            }
                          />
                        )
                      )}
                    </div>
                  )}
            </>
          ) : (
            <SportsBook
              handleShowInfo={handleShowInfo}
              data={sportsBook}
              user={user}
              detail={detail}
              setLimit={setLimit}
              scrollHeight={scrollHeight}
              tvClass={tvClass}
              setBetSlipObject={setBetSlipObject}
              setActiveSportBook={setActiveSportBook}
              activeSportBook={activeSportBook}
              setShowBetDialog={setShowBetDialog}
              profileData={
                detail?.matchSetting?.length > 0
                  ? { ...detail, arrayCheck: "details" }
                  : { ...profileData, arrayCheck: "profile" }
              }
            />
          )}
        </div>

        {/* {showWidget && widgetsData?.length > 0 && (
          <SportWidget data={widgetsData} detail={detail} />
        )} */}

        {showInfo && (
          <Info handleShowInfo={handleShowInfo} sportRules={sportRules} />
        )}

        {/* <PlaysBet /> */}

        {showMarketDepthPopup && (
          <MarketDepth setShowMarketDepthPopup={setShowMarketDepthPopup} />
        )}
        {/* <div className="bet-notification">
          <div onClick={() => handleShowBet()}>
            <img src="../assets/images/casino-icon/notifcation.png" alt="" />
            <span>1</span>
          </div>
        </div> */}

        {showMyBet && <MyBets handleShowBet={handleShowBet} />}
      </LayoutComponent>
    </div>
  );
};

export default FullMarket;
