import React, { useState, useEffect, useRef, useContext } from "react";
import { FaArrowsAltV } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { inRange, isEmpty, isEqual, isNumber } from "lodash";
import obj, { priceFormat } from "../../Utils/constants";
import BackLay from "../../components/BackLay";
import useScroll from "../../components/Scroll";
import { useNavigate } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
import BetSlipContext from "../../context/BetSlipContext";

const Runner = ({
  user,
  parmas,
  setRunner,
  detail,
  runner,
  setBetSlipObject,
  setShowBetDialog,
  backOdds,
  layOdds,
  setShowMarketDepthPopup,
  getMatchOdds,
  profileData,
  getBetPosition,
  setLimit,
  scrollHeight,
  tieData,
  back_oddsTie,
  lay_oddsTie,
  market,
  totalMatched,
  getMatchData,
  marketName,
  setMarketName,
  ms,
  before3Hr,
  oldBack,
  oldLay,
  position,
}) => {
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const naviagte = useNavigate();
  const [tab, setTab] = useState("matchOdds");
  const [localLimit, setLocalLimit] = useState({});
  const { showBetDialog } = useContext(BetSlipContext);
  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: tab == "tie" ? "tie" : "betFair",
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId:
              tab == "tie"
                ? detail?.tieMarketId
                : parmas?.marketId || detail?.marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  useEffect(() => {
    
    if (["Tied Match", "Completed Match"].includes(marketName)) {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "betFaire" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == detail?.gameType)
      );
      setLocalLimit(
        !isEmpty(obj) ? { ...obj, maxAmount: 20, maxProfit: 50 } : {}
      );
    } else if (["To Win the Toss"].includes(marketName)) {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "betFaire" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == detail?.gameType)
      );
      setLocalLimit(
        !isEmpty(obj) ? { ...obj, maxAmount: 100, maxProfit: 200 } : {}
      );
    } else {
      let result = profileData?.matchSetting?.find((res) => {
        return res?.type == marketName;
      });

      if (!isEmpty(result)) {
        let obj = result;
        if (!isEmpty(obj)) {
          setLocalLimit(obj);
        }
      } else {
        let obj = profileData?.matchSetting?.find(
          (res) =>
            res?.type == "betFaire" &&
            (profileData?.arrayCheck == "details"
              ? true
              : res?.sportType == detail?.gameType)
        );
        if (!isEmpty(obj)) {
          if (marketName == "Match Odds") {
            if (isEmpty(detail?.betLimitSetting)) {
              setLocalLimit(!isEmpty(obj) ? obj : {});
            } else {
              setLocalLimit(
                !isEmpty(obj)
                  ? {
                      ...obj,
                      minAmount: detail?.betLimitSetting?.minBet,
                      maxAmount: detail?.betLimitSetting?.maxBet,
                    }
                  : {}
              );
            }
          } else {
            setLocalLimit(obj);
          }
        } else {
          setLocalLimit({
            maxAmount: 500,
            maxProfit: 10000,
            minAmount: 1,
            oddsLimit: 20,
          });
        }
      }
    }
  }, [totalMatched, marketName]);

  // console.log(localLimit,'localLimit')
  return (
    <div className="match-odds-sec">
      <div
        className={`bg-yellow d-flex match-odd-header-outer ${
          detail?.gameType == "soccer" ? "match-odd-header-outer-soccer" : ""
        }`}
      >
        {market?.length > 1 ? (
          market?.map((res) => {
            return (
              <>
                <div
                  onClick={() => {
                    naviagte(
                      `/full-market/${res?.marketId}/${parmas?.eventId}`
                    );
                    setRunner(res?.jsonData || []);
                    getBetPosition(res?.marketId);
                    getMatchData(res?.marketId);
                    setMarketName(res?.market);
                  }}
                  style={{ marginRight: "7px", cursor: "pointer" }}
                  className={` d-flex align-items-center match-odd-header-text ${
                    parmas?.marketId == res?.marketId ? "match-odd-header" : ""
                  }`}
                >
                  <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
                  {res?.market}
                </div>
              </>
            );
          })
        ) : (
          <div
            onClick={() => {
              setTab("Match Odds");
              setMarketName("Match Odds");
            }}
            style={{ marginRight: "7px", cursor: "pointer" }}
            className={` d-flex align-items-center match-odd-header-text ${"match-odd-header"}`}
          >
            <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
            Match Odds
          </div>
        )}
      </div>

      <div className="match-odd-table bg-white p-3 px-2 py-1">
        <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
          <span className="text-center w-100">Back</span>
          <span className="text-center w-100">Lay</span>
        </div>
        <ul className="mb-2 rounded position-relative p-0">
          {runner?.map((item, index) => {
            const odds_back = backOdds?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            const odds_lay = layOdds?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            const old_back = oldBack?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            const old_lay = oldLay?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            let disabelBack =
              "lockBackBets" in item ? item?.lockBackBets : false;
            let disabelLay = "lockLayBets" in item ? item?.lockLayBets : false;

            const odds_lay2 =
              layOdds?.find((todd) => todd.ri === item.SelectionId) || {};
            let odds_back2 =
              backOdds?.find((todd) => todd.ri === item.SelectionId) || {};
            let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
            let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
            let disable = false;
            if (odds_lay?.length == 0 && odds_back?.length == 0) {
              disable = true;
            } else if (layRt <= 0 && backRt <= 0) {
              disable = true;
            } else if (
              (layRt > localLimit?.oddsLimit || layRt == 0) &&
              (backRt > localLimit?.oddsLimit || backRt == 0)
            ) {
              disable = true;
            } else {
              disable = false;
            }
            return (
              <li
                style={{ scrollMarginTop: scrollHeight }}
                ref={index == scrollId ? myRef : null}
                className="position-relative"
              >
                <h2>
                  {item?.RunnerName}
                  {(position?.length > 0 ||
                    Math.abs(item?.newPosition) > 0) && (
                    <span
                      className={`d-block py-1 ${
                        item?.position > 0
                          ? "text-green team-red-score"
                          : "team-red-score"
                      }`}
                    >
                      {position?.length > 0 && isNumber(item?.position)
                        ? item?.position > 0
                          ? Math?.abs(item?.position).toFixed(2)
                          : `( ${Math.abs(item?.position).toFixed(2)} )`
                        : ""}
                      {Math.abs(item?.newPosition) >= 0 && showBetDialog && (
                        <span
                          className={
                            item?.newPosition > 0
                              ? "text-green"
                              : "team-red-score"
                          }
                        >
                          {" "}
                          {">"}{" "}
                          {item?.newPosition > 0
                            ? Math.abs(item?.newPosition).toFixed(2)
                            : `( ${Math.abs(item?.newPosition).toFixed(2)} )`}
                        </span>
                      )}
                    </span>
                  )}
                </h2>
                <BackLay
                  component={"betfair"}
                  index={index}
                  marketName={marketName}
                  item={item}
                  disabelBack={disabelBack}
                  oldBack={old_back}
                  oldLay={old_lay}
                  conditionNew={disable && ms == 1}
                  disabelLay={disabelLay}
                  handelChange={handelChange}
                  odds_lay={odds_lay}
                  odds_back={odds_back}
                  limit={localLimit}
                  ms={ms}
                />
                {disable && ms == 1 && (
                  <div className="overlay-match">Suspended</div>
                )}
              </li>
            );
          })}
          {(before3Hr || ms != 1) && (
            <div className="overlay-match">Suspended</div>
          )}
        </ul>
        <div
          className="market-depth-sec d-flex"
          style={{ justifyContent: `end`, textAlign: `left` }}
        >
          {/* <Button
            className="text-white border-dark d-flex align-items-center"
            onClick={() => setShowMarketDepthPopup(true)}
          >
            <img src="../../assets/images/casino-icon/graph-icon.png" alt="" />
            Market Depth
          </Button> */}
          <div className="d-flex align-items-center min-max">
            <FaArrowsAltV />
            <span>min/max</span>
            <strong>
              {localLimit?.minAmount || 1}/
              {priceFormat(localLimit?.maxAmount) || 100}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Runner;
