import React, { useState, useEffect, useRef } from "react";
import { FaArrowsAltV } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { isEmpty, isEqual } from "lodash";
import obj, { priceFormat } from "../../Utils/constants";
import BackLay from "../../components/BackLay";
import useScroll from "../../components/Scroll";
import { useNavigate } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
import { useContext } from "react";
import BetSlipContext from "../../context/BetSlipContext";

const RunnerNew = ({
  user,
  parmas,
  betFairMs,
  detail,
  runner,
  setBetSlipObject,
  setShowBetDialog,
  backOdds,
  layOdds,
  profileData,
  setLimit,
  scrollHeight,
  market,
  totalMatched,
  title,
  res,
  newType,
  marketName
}) => {
  const myRef = useRef(null);
  const { setMessage } = useContext(BetSlipContext);
  const [scrollId, setScrollId] = useScroll(myRef);
  const naviagte = useNavigate();
  const [tab, setTab] = useState("matchOdds");
  const [localLimit, setLocalLimit] = useState({});
  const handelChange = (odds, type, team, item, index, title) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 4 && odds?.ib) {
        setMessage({
          message: "Back Bet Not Accept Rate Over 4.00",
          status: true,
          errorType: "Error",
        });
      } else {
        if (
          odds?.rt > 0 &&
          // totalMatched > 5000 &&
          detail?.status == "in_play" &&
          detail?.matchOdds == "on"
        ) {
          setScrollId(index);
          setShowBetDialog(true);
          setLimit(localLimit);
          setBetSlipObject((prev) => ({
            ...prev,
            odds: odds?.rt,
            teamName: team,
            betType: type,
            newType: newType,
            selectMarket: title,
            playType: tab == "tie" ? "tie" : "betFair",
            object: {
              ...item,
              ...odds,
              teamName: team,
              eventId: detail?.eventId,
              marketId: !isEmpty(market)
                ? res?.marketId
                : tab == "tie"
                ? detail?.tieMarketId
                : parmas?.marketId || detail?.marketId,
            },
          }));
        }
      }
    } else {
      naviagte("/login");
    }
  };
  useEffect(() => {
    let result = profileData?.matchSetting?.find((res) => {
      return res?.type == marketName;
    });
    if (!isEmpty(result)) {
      let obj = result;
      if (!isEmpty(obj)) {
        setLocalLimit(obj);
      }
    } else {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "betFaire" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == detail?.gameType)
      );
      if (!isEmpty(obj)) {
        setLocalLimit(obj);
      } else {
        setLocalLimit({
          maxAmount: 500,
          maxProfit: 10000,
          minAmount: 1,
          oddsLimit: 20,
        });
      }
    }
  }, [totalMatched, marketName]);  // useEffect(() => {
  //   if (detail?.gameType == "soccer") {
  //     if (marketName !== "Match Odds") {
  //       let obj = profileData?.matchSetting?.find((res) => {
  //         return res?.type == "over_under";
  //       });
  //       if (!isEmpty(obj)) {
  //         amountRangeFunc(totalMatched, obj);
  //       }
  //     } else {
  //       let obj = profileData?.matchSetting?.find(
  //         (res) =>
  //           res?.type == "betFaire" &&
  //           (profileData?.arrayCheck == "details"
  //             ? true
  //             : res?.sportType == details?.gameType)
  //       );
  //       if (!isEmpty(obj)) {
  //         amountRangeFunc(totalMatched, obj);
  //       }
  //     }
  //   }
  //   let obj = profileData?.matchSetting?.find(
  //     (res) =>
  //       res?.type == "betFaire" &&
  //       (profileData?.arrayCheck == "details"
  //         ? true
  //         : res?.sportType == detail?.gameType)
  //   );
  //   if (!isEmpty(obj)) {
  //     setLocalLimit({
  //       ...obj,
  //       maxAmount: obj?.maxAmount,
  //       minAmount: obj?.minAmount,
  //     });
  //   } else {
  //     setLocalLimit({
  //       maxAmount: 1000,
  //       maxProfit: 10000,
  //       minAmount: 1,
  //       oddsLimit: 10,
  //     });
  //   }
  // }, []);

  return (
    <div className="match-odds-sec">
      <div className="bg-yellow d-flex match-odd-header-outer">
        <div
          onClick={() => setTab("matchOdds")}
          style={{ marginRight: "7px", cursor: "pointer" }}
          className={`d-flex align-items-center match-odd-header-text w-100 ${
            tab == "matchOdds" ? "match-odd-header" : ""
          } justify-content-between`}
        >
          <span>
            <AiOutlineStar size={20} style={{ margin: "0px 4px" }} />
            {title}
          </span>
          <div
            className="text-red balance-label-inner"
            style={{ color: "black" }}
          >
            <span>Matched</span>
            <strong className="">
              {res?.totalMatched > 0 && `${priceFormat(res?.totalMatched)}`}
            </strong>
            {res?.totalMatched < 5000 && (
              <strong className="bg-danger rounded text-white low-button">
                Low
              </strong>
            )}
          </div>
        </div>
      </div>
      <div className="match-odd-table bg-white p-3 px-2 py-1">
        <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
          <span className="text-center w-100">Back</span>
          <span className="text-center w-100">Lay</span>
        </div>
        <ul className="mb-2 rounded position-relative p-0">
          {runner?.map((item, index) => {
            const odds_back = backOdds?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            const odds_lay = layOdds?.filter(
              (todd) => todd.ri === item.SelectionId
            );
            let disabelBack =
              "lockBackBets" in item ? item?.lockBackBets : false;
            let disabelLay = "lockLayBets" in item ? item?.lockLayBets : false;

            return (
              <li
                style={{ scrollMarginTop: scrollHeight }}
                ref={index == scrollId ? myRef : null}
              >
                <h2>
                  {item?.RunnerName}
                  {(Math?.abs(item?.position) > 0 ||
                    Math.abs(item?.newPosition) > 0) && (
                    <span
                      className={`d-block py-1 ${
                        item?.position > 0
                          ? "text-green team-red-score"
                          : "team-red-score"
                      }`}
                    >
                      {Math?.abs(item?.position) > 0
                        ? Math?.abs(item?.position).toFixed(2)
                        : ""}
                      {Math.abs(item?.newPosition) > 0 && (
                        <span
                          className={item?.newPosition > 0 ? "text-green" : ""}
                        >
                          {" "}
                          {">"} {Math.abs(item?.newPosition).toFixed(2)}
                        </span>
                      )}
                    </span>
                  )}
                </h2>
                <BackLay
                  component={"betfair"}
                  index={index}
                  betPlaceAccept={detail?.status == "in_play" ? true : false}
                  item={item}
                  disabelBack={disabelBack}
                  disabelLay={disabelLay}
                  handelChange={handelChange}
                  odds_lay={odds_lay}
                  odds_back={odds_back}
                  limit={localLimit}
                  title={title}
                />
              </li>
            );
          })}
          {/* {(
            (tab == "tie" ? lay_oddsTie : layOdds)?.filter(
              (res) => res?.bv > 0
            ) ||
            (tab == "tie" ? back_oddsTie : backOdds)?.filter(
              (res) => res?.bv > 0
            )
          )?.length == 0 && <div className="overlay-match">Suspended</div>} */}

          {betFairMs != 1 && (
            <div className="overlay-match">
              {betFairMs == 9
                ? "Ball Running"
                : betFairMs == 2
                ? "In Active"
                : betFairMs == 3
                ? "Suspended"
                : betFairMs == 4
                ? "Closed"
                : "Suspended"}
            </div>
          )}
        </ul>
        <div className="market-depth-sec d-flex justify-content-end">
          {/* <Button
            className="text-white border-dark d-flex align-items-center"
            onClick={() => setShowMarketDepthPopup(true)}
          >
            <img src="../assets/images/casino-icon/graph-icon.png" alt="" />
            Market Depth
          </Button> */}
          <div className="d-flex align-items-center min-max">
            <FaArrowsAltV />
            <span>min/max</span>
            <strong>
              {localLimit?.minAmount || 1}/
              {priceFormat(localLimit?.maxAmount) || 100}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunnerNew;
