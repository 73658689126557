import React, { useContext, useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty, toLower } from "lodash";
import { useNavigate } from "react-router-dom";
import DepositIcon from "../assets/icons/icon-deposit.svg";
import iconWithdrawal from "../assets/icons/icon-withdrawal.svg";
import iconBetReacord from "../assets/icons/icon-bet-records.svg";
import iconTurnover from "../assets/icons/icon-turnover.svg";
import iconRecords from "../assets/icons/icon-records.svg";
import iconWhatsapp from "../assets/images/icon-whatsapp.png";
import iconEmail from "../assets/images/icon-email.png";
import iconFb from "../assets/images/icon-facebook-messenger.png";
import iconLogout from "../assets/images/header-logout-icon.svg";
import iconProfile from "../assets/icons/icon-info.svg";
import { HiOutlineRefresh } from "react-icons/hi";

import iconLogins from "../assets/icons/icon-login-s.svg";
import iconRegister from "../assets/icons/icon-register.svg";
import iconBonuses from "../assets/icons/icon-bonuses.svg";
import iconPromotion from "../assets/icons/icon-promotion.svg";
import iconDownload from "../assets/icons/icon-download.svg";
import iconCustomer from "../assets/icons/icon-customer.svg";
import iconHome from "../assets/icons/icon-home.svg";
import iconLogin from "../assets/icons/icon-login.svg";
import iconArrow from "../assets/icons/icon-arrow.svg";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook, FaLink } from "react-icons/fa";

const MenuSidebarNew = () => {
  let {
    logoutUser,
    setShowSidebar,
    showSidebar,
    lang,
    user,
    setEn,
    setBr,
    launchEGTCasino,
    launchCasino,
    withoutLogin,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    handleShowLoginNotification,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  const [account, setAccount] = useState(false);

  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener("scroll", () => {
      if (window.scrollY > 180) {
        SetActiveClass("gamefixed");
      } else {
        SetActiveClass("");
      }
    });
  }, []);
  const { t } = useTranslation();

  const getDecimalsWithoutRounding = (num) => {
    var num1 = Math.floor(num * 100) / 100;
    return num1;
  };
  
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <>
        <button
          className="bg-transparent border-0 p-0 close-b"
          onClick={() => setShowSidebar(false)}
        >
          <RxCross2 />
        </button>

       
          <div className="member-menu-content bonuswallet">
            <button
              className="bg-transparent border-0 p-0 close-b"
              onClick={() => setShowSidebar(false)}
            >
              <RxCross2 />
            </button>
            <div className="menu-header ">
              {/* <video id="coinVideo" mcdvideo="" autoplay="" muted="" loop="" playsinline="" className="" poster="https://img.k39129.com/k9/h5/assets/images/animation/head-coin.png?v=1722411296535">
                        <source type="video/quicktime" className="" mcdsrc="/assets/images/animation/head-coin.mov" src="https://img.k39129.com/k9/h5/assets/images/animation/head-coin.mov?v=1722411296535">
                        <source type="video/webm" className="" mcdsrc="/assets/images/animation/head-coin.webm" src="https://img.k39129.com/k9/h5/assets/images/animation/head-coin.webm?v=1722411296535">
                      </video> */}

              <div id="account" className="account ">
                <span className="">{user?.user?.username}</span>
              </div>
            </div>

            <div className="member-menu-box balance-box">
              <div className="balance balance-row">
                <div className="text">
                  {" "}
                  Main Wallet{" "}
                  <div className="icon refresh">
                    <div onClick={amounutRefresh}>
                      <HiOutlineRefresh
                        size={20}
                        className={refreshAmountLoader ? "refresh-icon" : ""}
                      />
                    </div>
                  </div>
                  <div className="icon eyes"></div>
                </div>
                {refreshAmountLoader ? (
                  <div className="loader-outer">
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: "0s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".1s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".2s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".3s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".4s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".5s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".6s" }}
                    ></span>
                    <span
                      className="loader-inner-icon"
                      style={{ animationDelay: ".7s" }}
                    ></span>
                  </div>
                ) : (
                  <>
                    <span className="amount totalBalanceWallet">
                      <i>
                        <i id="locale-util-dicrective-0">
                        USD {getDecimalsWithoutRounding(userCoins?.balance)}
                        </i>
                      </i>
                    </span>
                  </>
                )}

                <span id="balanceHeader">-</span>
              </div>
            </div>

         
            <div className="member-menu-box member-list">
              <div className="title">
                <h2>
                  <span>History</span>
                </h2>
              </div>
              <ul className="align-center">
                <li className="total" onClick={() => navigate("/bets-history")}>
                  <a>
                    <span className="item-icon">
                      <img src={iconBetReacord} />
                    </span>
                    <p>Betting Records</p>
                  </a>
                </li>
                <li
                  className="water"
                  onClick={() => navigate("/profit-and-loss")}
                >
                  <a>
                    <span className="item-icon">
                      <img src={iconTurnover} />
                    </span>{" "}
                    <p>Turnover</p>
                  </a>
                </li>
                <li
                  className="history"
                  onClick={() => navigate("/account-statement")}
                >
                  <a>
                    <span className="item-icon">
                      <img src={iconRecords} />
                    </span>
                    <p>Transaction Records</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-menu-box member-list">
              <div className="title">
                <h2>
                  <span>Profile</span>
                </h2>
              </div>
              <ul>
                <li className="account" onClick={() => navigate("/my-profile")}>
                  <a>
                    <span className="item-icon">
                      <img src={iconProfile} />
                    </span>
                    <p>Personal Info</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="member-menu-box member-list csicons">
              <div className="title">
                <h2>
                  <span>Contact Us</span>
                </h2>
              </div>
              <ul className="align-center">
                <li
                  // onClick={() => {
                  //   window.open("https://wa.me/+19784445407", "_blank");
                  // }}
                  className="facebook-messenger"
                >
                  <img className="figure-icon" src={iconCustomer} alt="" />
                  <p className="">Customer Support</p>
                </li>
                <li
                  // onClick={() => {
                  //   window.open("https://www.facebook.com/groups/magicplay247.combd/", "_blank");
                  // }}
                  className="facebook-messenger "
                >
                  <FaFacebook color="#ffb61a" size={28} />
                  <p style={{ paddingTop: "5px" }} className="">
                    Facebook
                  </p>
                </li>
              </ul>
            </div>
            <div className="member-menu-logout">
              <a>
                <span className="item-icon me-2">
                  <img src={iconLogout} />
                </span>
                <div className="text" onClick={() => logoutUser()}>
                  <p>Log out</p>
                </div>
              </a>
            </div>
          </div>
        

       
      </>
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
